import React from 'react'
import { Link, graphql } from 'gatsby'
import _kebabCase from 'lodash/kebabCase'
import { DiscussionEmbed } from 'disqus-react'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark
        const siteTitle = this.props.data.site.siteMetadata.title
        const { previous, next } = this.props.pageContext
        const disqusShortname = 'gabriel-run';
        const disqusConfig = {
          identifier: post.id,
          title: post.frontmatter.title,
        };

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title={post.frontmatter.title} description={post.excerpt} />
                <h1>{post.frontmatter.title}</h1>
                <p
                    style={{
                        ...scale(-1 / 5),
                        display: `block`,
                        marginBottom: rhythm(1),
                        marginTop: rhythm(-1),
                    }}
                >
                    {post.frontmatter.date}
                    {!post.frontmatter.tags ? null : (
                        <div style={{ fontStyle: 'italic' }}>
                            {post.frontmatter.tags.map(tag => (
                                <React.Fragment>
                                    <Link to={'/tags/' + _kebabCase(tag)}>{tag}</Link>
                                    {' '}
                                </React.Fragment>
                            ))}
                        </div>
                )}
                </p>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                <hr
                    style={{
                        marginBottom: rhythm(1),
                    }}
                />
                <Bio />

                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.frontmatter.path} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.frontmatter.path} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>

                <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                tags
            }
        }
    }
`
